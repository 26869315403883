@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  :root {
    /* Custom Color Scheme */
    --background: 37 89% 96%; /* Светлый бежевый фон */
    --foreground: 0 0% 20%; /* Темный текст */
    --card: 0 0% 100%; /* Белый для карточек */
    --card-foreground: 0 0% 10%; /* Темный для текста карточек */
    --popover: 0 0% 98%; /* Белый фон для всплывающих элементов */
    --popover-foreground: 0 0% 20%; /* Темный текст всплывающих элементов */
    --primary: 45 100% 56%; /* Оранжевый основной */
    --primary-foreground: 0 0% 100%; /* Белый текст на кнопках */
    --secondary: 174 60% 45%; /* Зеленый акцент */
    --secondary-foreground: 0 0% 100%; /* Белый текст на зеленом */
    --muted: 0 0% 80%; /* Серый для muted-элементов */
    --muted-foreground: 0 0% 40%; /* Темный для текста muted-элементов */
    --accent: 200 100% 50%; /* Синий для акцентов */
    --accent-foreground: 0 0% 98%; /* Светлый текст на акцентах */
    --destructive: 0 84% 60%; /* Красный для destructive элементов */
    --destructive-foreground: 0 0% 100%; /* Белый текст на красных кнопках */
    --border: 0 0% 85%; /* Светлый серый для границ */
    --input: 0 0% 90%; /* Светло-серый для инпутов */
    --ring: 45 100% 56%; /* Оранжевый для фокусов */
    --radius: 0.5rem; /* Радиус для всех элементов */
    --text-color-black: #121212; /* Темный текст */
    --text-color-orange: 34, 97%, 58%; /* Темный текст */
    --text-color-grey: #4f4f4f; /* Темный текст */

    --main-orange: #fca12c;
    --main-black: #000;
    --primary-bg: #fff5e9;
    --secondary-bg: #f4f4f4; /* Светло-серый фон */
    --accent-bg: #0288a7; /* Темно-бирюзовый фон */
    --text-color: #333333; /* Темный текст */
    --text-color-black: #121212; /* Темный текст */
    --white-color: #ffffff; /* Белый цвет */
  }

  /* Темная тема */
  .dark {
    --background: 0 0% 10%; /* Темный фон */
    --foreground: 0 0% 100%; /* Светлый текст */
    --card: 0 0% 15%; /* Темные карточки */
    --card-foreground: 0 0% 90%; /* Светлый текст карточек */
    --popover: 0 0% 15%; /* Темный фон всплывающих элементов */
    --popover-foreground: 0 0% 98%; /* Светлый текст всплывающих элементов */
    --primary: 0 0% 98%; /* Светлый основной цвет */
    --primary-foreground: 0 0% 10%; /* Темный текст на светлом фоне */
    --secondary: 240 3.7% 15.9%; /* Темно-зеленый */
    --secondary-foreground: 0 0% 98%; /* Светлый текст на темном фоне */
    --muted: 240 3.7% 15.9%; /* Темные muted элементы */
    --muted-foreground: 0 0% 65%; /* Серый текст */
    --accent: 0 0% 90%; /* Светлый акцент */
    --accent-foreground: 0 0% 10%; /* Темный текст на светлых акцентах */
    --destructive: 0 60% 40%; /* Темный красный */
    --destructive-foreground: 0 0% 98%; /* Светлый текст */
    --border: 240 3.7% 15.9%; /* Темные границы */
    --input: 240 3.7% 20%; /* Темные инпуты */
    --ring: 240 4.9% 83.9%; /* Голубой для фокусов */
  }
}

@layer base {
  * {
    @apply border-border;
  }

  body {
    @apply bg-background text-foreground;
  }
}
